<!--
 * @Description: 注册
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-10 14:55:31
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 14:55:39
-->
<template>
  <div>注册</div>
</template>

<script>
  export default {
    name: 'Register',
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
</style>
